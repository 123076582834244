<template>
  <v-col cols="12" md="6">
    <h3 class="reservationTitle" v-text="$ml.get('confirmation.info')" />
    <v-row>
      <v-col cols="12" md="12" class="infoTravel" v-html="desc">
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "travelInfoConfirmation",
  props:['desc']
}
</script>

<style >
.reservationTitle {
  color: #d81d86;
  font-weight: bold;
  font-size: 18pt;
}
.infoTravel {
  text-align: justify;
}

.infoTravel h3 {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.2;
  color: black;
}

.infoTravel h4 {
  font-size: 90%;
  font-style: inherit;
  font-weight: bold;
  margin-bottom: 20px;
  color: black !important;
}

.infoTravel p {
  line-height: 1.6;
  margin: 0 0 1.75em;
}

.infoTravel b{
  font-weight: bold;
}
.infoTravel a{
  color:#d81d86;
}
</style>