<template>
  <v-row align="center" justify="center">
    <svg-top-gradient/>
    <v-col cols="12" md="10">
      <v-row align="center" justify="center">
        <v-col cols="10" md="6" v-if="!loaded">
          <v-skeleton-loader
              type="list-item-two-line"/>
        </v-col>
        <v-col cols="10" md="6" v-if="!loaded">
          <v-skeleton-loader
              type="image"/>
        </v-col>
        <v-col cols="10" md="6" v-if="loaded" v-show="!printing" >
          <head-confirmation-info :travel="travel" :resref="reservation.ref" :payment-o-k="true" :print="printWindow"/>
        </v-col>
        <v-col cols="10" md="6" v-if="loaded"  class="no-print">
          <v-img height="400" aspect-ratio="1"
                 :src="this.image_url+travel.image"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="10">
      <v-row align="top" justify="center">
        <travel-info-confirmation :desc="travel.descriptionES"  v-if="$ml.current === 'Esp' && loaded"/>
        <travel-info-confirmation :desc="travel.descriptionCAT"  v-else-if="$ml.current === 'Cat' && loaded"/>

        <reservation-info :typus="travel.typus" :reservation="reservation" :seats="seats" :files="files"
                          :passengers="passengers"  v-if="loaded"/>
        <files-info :files="files"  class="no-print"/>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import headConfirmationInfo from "../components/confirmation/headInfoConfirmation";
import SvgTopGradient from "../components/partials/svgTopGradient";
import TravelInfoConfirmation from "../components/confirmation/travelInfoConfirmation";
import ReservationInfo from "../components/confirmation/reservationInfo";
import FilesInfo from "../components/confirmation/filesInfo";

export default {
  name: "Confirmation",
  components: {
    FilesInfo,
    ReservationInfo,
    TravelInfoConfirmation,
    SvgTopGradient,
    headConfirmationInfo
  },
  data() {
    return {
      travel: [],
      reservation: [],
      passengers: [],
      seats: [],
      files: [],
      loaded: false,
      printing: false
    }
  },
  mounted() {
    this.getData()
    this.refreshUnconfirmedReservations()
  },
  methods: {
    refreshUnconfirmedReservations() {
      let url = this.base_url + "/admin/borrarpendientes";
      this.axios.get(url).then(() => {
      })
    },
    getData() {
      let urlconf;
      if(this.$ml.current === 'Esp')
        urlconf = this.base_url + "/client/confirmarviaje/" + this.$route.params.ref + "/" + this.$route.params.idc +"/1"
      else  urlconf = this.base_url + "/client/confirmarviaje/" + this.$route.params.ref + "/" + this.$route.params.idc +"/2"
      this.axios.get(urlconf).then(()=>{
        this.$ma.trackEvent({
          category: 'Confirmation',
          action: 'confirm reservation request',
          properties: {version: this.version, rid:this.$route.params.idc}
        });
      }).catch(err=>{
        if(err.response.status === 500){
          if(err.response.data.message == 'already confirmed')
            this.$ma.trackEvent({
              category: 'Confirmation',
              action: 'reservation already confirmed',
              properties: {version: this.version, rid:this.$route.params.idc}
            });
          else{
            this.$ma.trackEvent({
              category: 'Confirmation',
              action: 'error when confirm reservation',
              properties: {version: this.version, rid:this.$route.params.idc}
            });
            window.location.reload(true);
          }
        }else{
          this.$ma.trackEvent({
            category: 'Confirmation',
            action: 'error when confirm reservation',
            properties: {version: this.version, rid:this.$route.params.idc}
          });
          window.location.reload(true);
        }
      })
      let url = this.base_url + "/client/confirmacion/" + this.$route.params.ref + "/" + this.$route.params.idc
      this.axios.get(url).then(response => {
        this.travel = response.data.travel;
        this.reservation = response.data.reservation;
        this.passengers = response.data.passengers;
        this.seats = response.data.seats;
        this.files = response.data.files;
        this.loaded = true;
        this.$ma.trackEvent({
          category: 'Confirmation',
          action: 'get confirmation data',
          properties: {version: this.version, rid:this.$route.params.idc}
        });
      }).catch(()=>{
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'failed get confirmation data',
          properties: {rid:this.$route.params.idc,version:this.version}
        });
      });
    },
    printWindow: function () {
      this.printing = true;
      window.print();
      this.printing= false;
    }
  }
}
</script>
<style scoped>
.reservationTitle {
  color: #d81d86;
  font-weight: bold;
  font-size: 18pt;
}


</style>

